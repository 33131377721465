(function () {
    'use strict';

    angular.module("adminApp")
        .factory('aflAdminUserModal', ["$uibModal", "aerosAdminApi", function ($uibModal, aerosAdminApi) {

            function open(user, action, adminRoles, orgRoles) {
                var modal = $uibModal.open({
                    size: "md",
                    templateUrl: "/static/javascript/directives/afl-admin-user-modal/afl-admin-user-modal.html",
                    controller: aflAdminUserModalController,
                    controllerAs: '$ctrl',
                    resolve: {
                        user: function () {
                            //return user;

                            if (action !== "create") {
                                return aerosAdminApi.getAdminUser(user.username)
                                    .then(function (res) {
                                        if (res.data && res.data.user) {
                                            return res.data.user;
                                        }
                                    });
                            } else {
                                return user;
                            }

                        },
                        action: function () {
                            return action;
                        },
                        adminRoles: function () {
                            return adminRoles;
                        },
                        orgRoles: function () {
                            return orgRoles;
                        }
                    }
                });
                return modal.result;
            }

            return {
                open: open
            };
        }]);

    aflAdminUserModalController.$inject = ["$uibModalInstance", "Notification", "aerosAdminApi",
        "aflAdminUserDeleteModal", "user", "action", "adminRoles", "orgRoles"];

    function aflAdminUserModalController($uibModalInstance, Notification, aerosAdminApi, aflAdminUserDeleteModal, user,
                                         action, adminRoles, orgRoles) {
        var $ctrl = this;

        (function init() {
            angular.extend($ctrl, {
                getTitle: getTitle,
                isEdit: isEdit,
                isCreate: isCreate,
                isReadOnly: isReadOnly,
                deleteUser: deleteUser
            });

            $ctrl.systemRoles = adminRoles;
            $ctrl.orgRoles = orgRoles;
            $ctrl.userModel = {
                "email": "",
                "firstName": "",
                "lastName": ""
            };

            $ctrl.action = action;
            $ctrl.selection = [];
            $ctrl.toggleSelection = function toggleSelection(roleId) {
                var idx = $ctrl.selection.indexOf(roleId);
                if (idx > -1) {
                    $ctrl.selection.splice(idx, 1);
                } else {
                    $ctrl.selection.push(roleId);
                }
            };


            if (user !== null) {
                $ctrl.userModel.email = user.email;
                $ctrl.userModel.firstName = user.firstName;
                $ctrl.userModel.lastName = user.lastName;
                $ctrl.userModel.orgRolesByOrganizations = user.orgRolesByOrganizations;

                if (user.systemRoles) {
                    _.each(user.systemRoles, function (role) {
                        //If not valid role, then don't add (clears out old roles)
                        if (_.findIndex($ctrl.systemRoles, {id: role}) > -1) {
                            $ctrl.selection.push(role);
                        }
                    });
                }
            }

            setAllowedToDelete();
        })();

        $ctrl.cancel = function () {
            $uibModalInstance.dismiss();
        };

        $ctrl.saveUser = function () {
            $ctrl.userModel.systemRoles = angular.copy($ctrl.selection);
            if (isEdit()) {
                aerosAdminApi.updateAdminUser($ctrl.userModel)
                    .success(function () {
                        $uibModalInstance.close();
                        Notification.success("User " + $ctrl.userModel.email + " updated");
                    }).error(function (err) {
                    Notification.error("User " + $ctrl.userModel.email + " not updated. " + err.message);
                });
            } else {
                aerosAdminApi.inviteAdminUser($ctrl.userModel)
                    .success(function () {
                        $uibModalInstance.close();
                        Notification.success("User " + $ctrl.userModel.email + " invited");
                    }).error(function (err) {
                    Notification.error("User " + $ctrl.userModel.email + " not invited. " + err.message);
                });
            }
        };

        function getTitle() {
            switch ($ctrl.action) {
                case "create":
                    return "Create Profile";
                case "edit":
                    return "Edit Profile";
                case "view":
                    return "View Profile";
                default:
                    return "Profile";
            }
        }

        function isEdit() {
            return $ctrl.action === "edit";
        }

        function isCreate() {
            return $ctrl.action === "create";
        }

        function isReadOnly() {
            return !isEdit() && !isCreate();
        }

        function deleteUser() {
            aflAdminUserDeleteModal.open($ctrl.userModel).then(function() {
                $uibModalInstance.close();
            });
        }

        function setAllowedToDelete() {
            $ctrl.allowedToDelete = !$ctrl.isReadOnly() &&
                !$ctrl.orgRolesByOrganizations && $ctrl.selection.length !== 0;
        }
    }
})();
